import React from "react";
import { AiOutlineInstagram, AiOutlineWhatsApp } from "react-icons/ai";
import { RxEnvelopeClosed } from "react-icons/rx";
import { Link } from "react-router-dom";

export default function Footer({ className }) {
  return (
    <footer className={`bg-black text-white pt-8 pb-10 ${className}`}>
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between lg:w-full gap-3 custom-container">
        <div className="lg:w-1/3">
          <div className="my-[20px]">
            <img
              src="/assets/logo-white.svg"
              className="w-[70px] lg:w-[100px]"
              alt=""
            />
          </div>
          <p className="text-[14px]">
            Jl. Ciputat Raya No.30A, Kby. Lama Sel., Kec. Kby. Lama, Kota
            Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12240. (Gedung Baru
            sebelah JVS Grup).
          </p>
        </div>
        <div className="lg:w-2/3">
          <ul className="flex flex-col lg:flex-row lg:justify-end gap-3 lg:gap-10">
            <li>
              <Link className="text-[14px] hover:underline" to="/products">
                Produk
              </Link>
            </li>
            <li>
              <Link className="text-[14px] hover:underline" to="/faq">
                FAQ
              </Link>
            </li>
            <li>
              <a
                className="text-[14px] hover:underline"
                href="https://wa.me/+6281212845495"
                target="_blank"
                rel="noreferrer"
              >
                Hubungi Kami
              </a>
            </li>
            <li>
              <a
                className="text-[14px] hover:underline"
                href="https://berdu.id/cek-resi"
                target="_blank"
                rel="noreferrer"
              >
                Lacak Pengiriman
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="h-[2px] lg:bg-white custom-container my-4 "></div>
      <div className="custom-container flex flex-col lg:flex-row lg:justify-between gap-3">
        <ul className="flex flex-col lg:flex-row gap-3 md:w-1/3">
          <li>
            <Link
              className="text-[14px] hover:underline"
              to="/syarat-dan-ketentuan"
            >
              Syarat & Ketentuan
            </Link>
          </li>
          <li>
            <Link
              className="text-[14px] hover:underline"
              to="/kebijakan-garansi"
            >
              Kebijakan Garansi
            </Link>
          </li>
        </ul>
        <ul className="flex flex-col lg:flex-row gap-3 md:gap-5 mt-4 lg:mt-0 lg:w-2/3 lg:justify-end">
          <li className="flex">
            <a
              className="text-[14px] hover:underline flex gap-2 items-center"
              href="mailto:sales@idpods.co.id"
              target="_blank"
              rel="noreferrer"
            >
              <RxEnvelopeClosed className="inline w-4 h-4 mr-2" />{" "}
              <span className="inline">sales@idpods.co.id</span>
            </a>
          </li>
          <li>
            <a
              className="text-[14px] hover:underline"
              href="//wa.me/6281212845495"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineWhatsApp className="inline w-4 h-4 align-middle mr-2" />
              +6281212845495
            </a>
          </li>
          <li>
            <a
              className="text-[14px] hover:underline"
              href="https://www.instagram.com/idpods.official/"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineInstagram className="inline w-4 h-4 mr-2" />
              idpods.official
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
